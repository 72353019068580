/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { ButtonGroup, Typography } from "@material-ui/core";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from "@material-ui/lab";
import Filter from "views/components/Filter";
import HeaderButtonGroup from "views/components/Headers/HeaderButtonGroup";
import { withSnackbar } from "notistack";
import { Component } from "react";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import BoxLoading from "react-loadingg/lib/BoxLoading";
import { Button, Card, CardHeader, Container, FormGroup, Input, Modal, ModalBody, ModalHeader, Row, Table, UncontrolledTooltip } from "reactstrap";
import Col from "reactstrap/lib/Col";
import api from "services/api";
import cpfMask from "utils/cpfMask";
import { jsonToUrlFilter } from "utils/filter";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import Config from "Config";
import ButtonControl from "views/components/ButtonControl";

class Visitantes extends Component {
  state = {
    data: [],
    loading: false,
    loadingMore: false,
    filter: {
      _page: 1,
      _limit: 20,
      _order: 'ASC',
      _sort: "nome"
    }
  };

  handlePesquisar = async (removeData = true) => {
    const { data, filter, loading } = this.state;
    if (loading) return;

    let filterTmp = { ...filter };

    await this.setState({ loading: removeData, loadingMore: !removeData });

    if (removeData && filter._page > 1) {
      await this.setState({ filter: { ...filter, _page: 1 } })
    }

    if ('nome_like' in filter && filter.nome_like.length === 0) {
      delete filterTmp.nome_like;
    }

    if ('cpf_like' in filter && filter.cpf_like.length === 0) {
      delete filterTmp.cpf_like;
    }

    const response = await api.get(`api/visitantes?${jsonToUrlFilter(filterTmp)}`);

    for (const key in response.data) {
      const visitante = response.data[key];

      const filter = {
        visitante: { id: visitante.id },
        data_entrada: 'null',
        cancelado: 'false',
        _page: 1,
        _limit: 99999,
        _order: 'desc',
        _sort: "data_cadastro"
      };
      const responseHistorico = await api.get(`api/visitantes/historico?${jsonToUrlFilter(filter)}`);
      response.data[key].aprovar = !!responseHistorico && !!responseHistorico.length ? responseHistorico.data[0] : null;
    }

    if (!!response) {
      if (removeData) {
        this.setState({
          ...response,
          loading: false,
          loadingMore: false
        })
      }
      else {
        this.setState({
          ...response,
          data: [...data, ...response.data],
          loading: false,
          loadingMore: false
        })
      }
    }
    else {
      this.setState({ loading: false, loadingMore: false })
    }
  }

  loadMore = () => {
    const { filter } = this.state;

    this.setState({
      filter: {
        ...filter,
        _page: this.state.filter._page + 1
      }
    }, () => {
      this.findAssociados(false);
    });
  }

  handleLimpar = () => {
    this.setState({
      data: [],
      length: 0,
      total_data: 0,
      filter: {
        _limit: 50,
        _order: 'ASC',
        _sort: "name"
      }
    });
  }

  handleAprovar = async item => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let observacao = "";

        return (
          <div className="react-confirm-alert-body">
            <h1>Deseja aprovar a entrada?</h1>

            Esta ação não poderá ser desfeita.
            <Col xs="12">
              <FormGroup>
                <label className="form-control-label">Observação</label>
                <Input
                  type="textarea"
                  className="form-control"
                  rows={5}
                  onChange={value => observacao = value.target.value }
                />
              </FormGroup>
            </Col>

            <div className="react-confirm-alert-button-group">
              <button onClick={onClose}>Não</button>
              <button onClick={async () => {
                this.setState({ loadingFull: true });

                const user = JSON.parse(window.localStorage.getItem(Config._USER));
                item.aprovar.autorizado_by = { id: user.id };
                item.aprovar.data_entrada = moment().format('YYYY-MM-DDTHH:mm:ss');
                if(observacao.length > 0) item.aprovar.observacao = observacao;

                const response = await api.put(`api/visitantes/historico/${item.aprovar.id}`, item.aprovar);

                if(!!response) {
                  const { data } = this.state;

                  for (const key in data) {
                    if(data[key].id === item.id) {
                      data[key].aprovar = null;
                    }
                  }

                  this.setState({ loadingFull: false, data });
                  this.props.enqueueSnackbar("Entrada aprovada!", { variant: 'success' });
                  onClose();
                }
                else {
                  this.setState({ loadingFull: false });
                  this.props.enqueueSnackbar("Ops! Ocorreu algum erro, tente novamente mais tarde.");
                }
              }}>
                Sim
              </button>
            </div>
          </div>
        );
      }
    })
  }

  handleShowHistorico = async item => {
    this.setState({ loadingFull: true });
    const filter = {
      visitante: { id: item.id },
      _page: 1,
      _limit: 99999,
      _order: 'desc',
      _sort: "data_cadastro"
    };
    const response = await api.get(`api/visitantes/historico?${jsonToUrlFilter(filter)}`);

    if(!!response) {
      this.setState({ loadingFull: false, showModalHistorico: true, modalBody: response.data });
    }
    else {
      this.setState({ loadingFull: false });
      this.props.enqueueSnackbar("Ops! Ocorreu algum erro, tente novamente mais tarde.");
    }
  }

  modalHistorico = () => {
    const { showModalHistorico, modalBody } = this.state;

    return (
      <>
        <Modal className="modal-lg" isOpen={showModalHistorico} toggle={() => this.setState({ showModalHistorico: !showModalHistorico })}>
          <ModalHeader toggle={() => this.setState({ showModalHistorico: !showModalHistorico })}>Histórico de visitas</ModalHeader>
          <ModalBody>
            <Timeline>
              <TimelineItem>
                <TimelineOppositeContent>
                  <Typography color="textSecondary">RESPONSÁVEL PELO CONVITE</Typography>
                </TimelineOppositeContent>
                <TimelineSeparator />
                <TimelineContent>
                  <Typography color="textSecondary">STATUS DA VISITA</Typography>
                </TimelineContent>
              </TimelineItem>

              {!!modalBody && modalBody.map(item => (
                <TimelineItem key={item.id}>
                  <TimelineOppositeContent>
                    <Typography color="textSecondary">{item.usuario.name} - {moment(item.data_cadastro).format('DD/MM/YYYY HH:mm')}</Typography>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography>
                      {!!item.cancelado ? "Cancelado"
                      : !item.data_entrada ? "Em Aberto"
                      : !!item.autorizado_by && (
                        <>
                          <span href="#" id={`historico-${item.id}`}>Aprodado</span>
                          <UncontrolledTooltip placement="top" target={`historico-${item.id}`}>
                            <strong>Aprovado por: </strong>{item.autorizado_by.name}
                            {!!item.observacao && (
                              <>
                              <br />
                              <br />
                              <strong>Observação: </strong> {item.observacao}</>
                            )}
                          </UncontrolledTooltip>
                        </>
                      )}
                      {!!item.data_entrada && <span> - {moment(item.data_entrada).format('DD/MM/YYYY HH:mm')}</span>}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              ))}

              <TimelineItem>
                <TimelineSeparator>
                  <TimelineDot color="default" variant="outlined">
                    <ArrowUpwardIcon />
                  </TimelineDot>
                </TimelineSeparator>
                <TimelineContent />
              </TimelineItem>

            </Timeline>
          </ModalBody>
        </Modal>
      </>
    );
  }

  renderContent() {
    const { data, length, total_pages, filter, loading } = this.state;

    if (loading) return <BoxLoading />;

    if (length === undefined || length === 0) {
      return (
        <CardHeader className="border-0">
          <h3 className="mb-0">Nenhum visitante encontrado!</h3>
        </CardHeader>
      )
    }

    return (
      <div>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th scope="col">NOME</th>
              <th scope="col">CPF</th>
              <th scope="col">TELEFONE</th>
              <th scope="col">AÇÃO</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => {
              return (
                <tr key={item.id}>
                  <td>{item.nome}</td>
                  <td>{item.cpf}</td>
                  <td>{item.telefone}</td>
                  <td>
                    <div className="avatar-group">
                      <button className="btn" onClick={() => this.handleShowHistorico(item)}>
                        <i className="fas fa-history mt-margin-xsmall-right"></i>
                        Histórico
                      </button>
                      {!!item.aprovar && (
                        <button className="btn btn-success" onClick={() => this.handleAprovar(item)}>
                          <i className="fas fa-thumbs-up mt-margin-xsmall-right"></i>
                          Aprovar entrada
                        </button>
                      )}
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>

        {filter._page < total_pages && (
          <Button style={{ backgroundColor: "#3f51b5", color: "white", width: '100%', height: "45px" }} disabled={this.state.loadingMore} variant="contained" onClick={this.loadMore}>
            BUSCAR MAIS {this.state.loadingMore && <BoxLoading />}
          </Button>
        )}

      </div>
    )
  }

  render() {
    const { filter, total_data, loadingFull } = this.state;
    const user = JSON.parse(window.localStorage.getItem(Config._USER));

    return (
      <>
        {this.modalHistorico()}

        {loadingFull && (
          <div className="container-load-with-margin">
            <BoxLoading />
          </div>
        )}

        <HeaderButtonGroup
          component={
            <div className="text-center">
              <ButtonGroup color="primary" aria-label="outlined primary button group">
                {!!user && (
                  <ButtonControl color='secondary' target="_blank" rel="noopener noreferrer" to={`/visitante/${user.uk}`}>
                    <i className="fas fa-plus"></i> NOVO
                  </ButtonControl>
                )}
              </ButtonGroup>
            </div>
          }
          titulo={"VISITANTES"}
          subTitulo={"Módulo de Gestão para Visitantes"}
        />

        <Container className="mt--7" fluid>
          <Row>
            <Col lg="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 style={{ float: "left" }} className="mb-0">Visitantes ({total_data ? total_data : 0})</h3>
                  <Filter handleLimpar={this.handleLimpar} handlePesquisar={this.handlePesquisar} isOpened={true}>
                    <Row>
                      <Col lg="5">
                        <FormGroup>
                          <Input
                            className="form-control"
                            placeholder="Nome"
                            type="text"
                            onChange={value => {
                              this.setState({ filter: { ...filter, nome_like: value.target.value.toUpperCase() } })
                            }}
                            value={'nome_like' in filter ? filter.nome_like : ''}
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <Input
                            className="form-control"
                            id="input-last-name"
                            placeholder="CPF ou CNPJ"
                            type="text"
                            onChange={(value) => {
                              this.setState({ filter: { ...filter, cpf_like: cpfMask(value.target.value) } })
                            }}
                            value={'cpf_like' in filter ? filter.cpf_like : ''}
                            autoComplete="off"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Filter>
                </CardHeader>
                {this.renderContent()}
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }

}

export default withSnackbar(Visitantes);
